"use strict";
var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import { DcBaseComponent } from "@deleteagency/dc";
import { PageLocker } from "project/general/js/page-locker";
const VISIBLE_CLASS = "is-visible";
export default class ContactPaneComponent extends DcBaseComponent {
  constructor(...args) {
    super(...args);
    __publicField(this, "openPanel", () => {
      this.pageLocker.lock();
      this.refs.panel.classList.add(VISIBLE_CLASS);
    });
    __publicField(this, "closePanel", () => {
      this.pageLocker.unlock();
      this.refs.panel.classList.remove(VISIBLE_CLASS);
    });
    this.pageLocker = new PageLocker();
    this.pageLocker.setOptions({ target: document.body });
  }
  static getNamespace() {
    return "contact-pane";
  }
  static getRequiredRefs() {
    return [
      "openButtons",
      "panel",
      "closeButton"
    ];
  }
  onInit() {
    this.addListener(document, "keydown", (event) => {
      if (event.keyCode === 27) {
        this.closePanel();
      }
    });
    this.refs.openButtons.forEach((button) => {
      this.addListener(button, "click", this.openPanel);
    });
    this.addListener(this.refs.closeButton, "click", this.closePanel);
  }
  onDestroy() {
    this.pageLocker = null;
  }
}
